<i18n>
{
  "en": {
    "run:parse": "Parse",
    "run:parsing": "Parsing...",
    "run:index": "Index",
    "run:indexing": "Indexing...",
    "run:create_index": "Create index",
    "run:pack": "Pack",
    "run:packing": "Packing..."
  },
  "fr": {
    "run:parse": "Analyser",
    "run:parsing": "Analyse en cours",
    "run:index": "Indexer",
    "run:indexing": "Indexation en cours",
    "run:create_index": "Créer un index",
    "run:pack": "Pack",
    "run:packing": "Pack en cours"
  }
}
</i18n>

<template>
  <span>
    <div v-show="isShown" @click="toggleSelection" @dblclick="updateNode"
         :class="{active: active, selected: isSelected, 'collection-node': true, 'row': true}"
         @mouseenter="toggleActive" @mouseleave="toggleActive">

      <!--display collection node general info: type + title + configuration  -->
      <span :style="indent" class="col-md-7 collection-info">
        <!--Indentation chevron-->
        <span class="treeview-toggle" @click="toggleChildren"
              :class="hasChildren ? expanded ? 'glyphicon glyphicon-chevron-down' : 'glyphicon glyphicon-chevron-right' : ''"></span>
        <!--display collection type-->
        <span v-show="isFolder" class="label label-default collection-type">DIR</span>
        <span v-show="isPublication" class="label label-primary collection-type">PUB</span>
        <span :class="{'collection-title': true, 'not-published': isNotPublished, 'obsolete': isObsolete}">{{ model.title }}</span>
        <span class="badge" v-if="hasChildren && !expanded">{{nbChildren}}</span>
        <span v-if="isDesc && !isDocument" class="glyphicon glyphicon-sort-by-alphabet-alt" title="Specific configuration"></span>
      </span>

      <span class="col-md-3">
        <span :id="'btnParseStatus' + model.id" v-show="canParse">
            <button type="button" class="btn btn-xs btn-warning disabled" v-if="isParsing">{{ $t('run:parsing') }}</button>
            <button type="button" class="btn btn-xs btn-danger" v-else-if="toParse" @click="startTask('parse')">{{ $t('run:parse') }}</button>
            <button type="button" class="btn btn-xs btn-success" v-else @click="startTask('parse')">{{ $t('run:parse') }}</button>
        </span>

        <span :id="'btnIndexStatus' + model.id" v-show="canIndex">
            <button type="button" class="btn btn-xs btn-warning disabled" v-if="isIndexing">{{ $t('run:indexing') }}</button>
            <button type="button" class="btn btn-xs btn-danger" v-else-if="toIndex" @click="startTask('index')">{{ $t('run:index') }}</button>
            <button type="button" class="btn btn-xs btn-success" v-else-if="hasIndex"
                    @click="startTask('index')">{{ $t('run:index') }}</button>
          <button type="button" class="btn btn-xs btn-default" v-else-if="canIndex"
                  @click="createPublication">{{ $t('run:create_index') }}</button>
        </span>

        <span :id="'btnPackStatus' + model.id" v-show="canPack">
          <button type="button" class="btn btn-xs btn-warning disabled" v-if="isPacking">{{ $t('run:packing') }}</button>
          <button type="button" class="btn btn-xs btn-danger" v-else-if="toPack" @click="startTask('off')">{{ $t('run:pack') }}</button>
          <button type="button" class="btn btn-xs btn-success" v-else @click="startTask('off')">{{ $t('run:pack') }}</button>
        </span>
      </span>

      <!--display actions on collection node-->
      <span class="col-md-2">
        <a @click="createNode" v-show="isCollection"><span class="glyphicon glyphicon-plus"
                                                           title="Add new child category"></span></a>
        <a @click="updateNode"><span class="glyphicon glyphicon-edit" title="Edit"></span></a>
        <a @click="deleteNode" v-show="isCollection & !hasChildren"><span class="glyphicon glyphicon-trash"
                                                           title="Delete category (if empty)"></span></a>
        <a @click="upload" v-show="isCollection"><span class="glyphicon glyphicon-import"
                                                       title="Add content in this category"></span></a>
        <a @click="deleteNode" v-show="isDocument | isMaster"><span class="glyphicon glyphicon-trash"
                                                         title="Delete document"></span></a>
        <!--<span v-show="active" class="glyphicon glyphicon-sort pull-right"></span>-->
      </span>
    </div>

    <collection-node
      v-if="hasLoadedChildren"
      v-for="node in model.children"
      v-show="expanded"
      :key="node.id"
      :model="node"
      :depth="depth + 1">
    </collection-node>
  </span>
</template>

<script>
  import EventBus from './event-bus'
  import axios from 'axios'

  export default {
    name: 'collection-node',
    // components: {ActionButton},
    props: {
      model: Object,
      depth: Number
    },
    data () {
      return {
        expanded: false,
        active: false,
        isSelected: false,
        isShown: true,
        isPublished: false,
        isObsolete: false
      }
    },
    watch: {
      published: function () {
        this.model.published = this.isPublished
      },
      'model.title': function (val, oldVal) {
        if (val !== oldVal) {
          let parser = this.getParser
          if (parser) {
            this.$set(parser.model, 'parse_task', 'to do')
          }
        }
      },
      'model.code': function (val, oldVal) {
        if (val !== oldVal) {
          this.$set(this.model, 'index_task', '0')
        }
      },
      'model.can_index': function (val, oldVal) {
        if (val !== oldVal && oldVal === true) {
          this.$set(this.$parent.model, 'can_index', false)
        }
      },
      'model.is_desc': function (val, oldVal) {
        if (val !== oldVal) {
          this.sortChildren()
        }
      },
      'model.published': function (val, oldVal) {
        if (val !== oldVal) {
          this.model.children.forEach(function (child) {
            child.published = val
          })
        }
      },
      'model.obsolete': function (val, oldVal) {
        if (val !== oldVal) {
          this.model.children.forEach(function (child) {
            child.obsolete = val
          })
        }
      }
    },
    computed: {
      isRoot () {
        return this.depth === 0
      },
      isCollection () {
        return this.model.type === 'COL'
      },
      isPublication () {
        return this.model.type === 'PUB'
      },
      isFolder () {
        return this.model.type === 'DIR' && this.hasChildren
      },
      isDocument () {
        return this.model.type === 'DIR' && !this.hasChildren
      },
      isMaster () {
        return (this.model.parse_status !== null)
      },
      nbChildren () {
        return this.model.nb_children
      },
      hasChildren () {
        return (this.model.nb_children > 0)
      },
      hasLoadedChildren () {
        return (this.model.children && this.model.children.length)
      },
      isDesc () {
        return (this.model.is_desc === true)
      },
      isNotPublished () {
        if (this.isCollection) {
          return false
        }
        return (this.model.published === false)
      },
      hasIndex () {
        return (this.model.index_status !== null)
      },
      isIndexing () {
        return (this.model.index_status === 'PROGRESS' || this.model.index_status === 'PENDING' || this.model.index_status === 'STARTED')
      },
      toIndex () {
        return (this.model.index_status === 'TODO' || this.model.index_status === 'FAILURE' || this.model.index_status === 'CANCELLED')
      },
      canIndex () {
        return this.parsed && this.model.can_index && !this.isPacking
      },
      canParse () {
        return (this.model.parse_status !== null) && !this.isIndexing && !this.isPacking
      },
      canPack () {
        return (this.model.parse_status !== null) && this.parsed && this.indexed
      },
      isPacking () {
        return (this.model.pack_status === 'PROGRESS' || this.model.pack_status === 'PENDING' || this.model.pack_status === 'STARTED')
      },
      toPack () {
        return (this.model.pack_status === null || this.model.pack_status === 'TODO' || this.model.pack_status === 'FAILURE' || this.model.pack_status === 'CANCELLED')
      },
      packed () {
        return !this.isPacking && !this.toPack
      },
      isParsing () {
        return (this.model.parse_status === 'PROGRESS' || this.model.parse_status === 'PENDING' || this.model.parse_status === 'STARTED')
      },
      toParse () {
        return (this.model.parse_status === 'TODO' || this.model.parse_status === 'FAILURE' || this.model.parse_status === 'CANCELLED')
      },
      parsed () {
        return !this.isParsing && !this.toParse
      },
      indexed () {
        return this.parsed && !this.isIndexing && !this.toIndex
      },
      getParser () {
        if (this.canParse) {
          return this
        }

        let counter = this.depth
        let curItem = this
        while (counter > 0) {
          if (curItem.canParse) {
            return curItem
          }

          if (curItem.isRoot) {
            return null
          }

          counter = counter - 1
          curItem = curItem.$parent
        }
      },
      indent () {
        let margin = 0
        if (this.active) {
          margin = 0
        }
        if (this.hasChildren) {
          return 'padding-left:' + (this.depth * 30 - 15) + 'px;margin-left:' + margin + 'px;'
        }

        return 'padding-left:' + this.depth * 30 + 'px;margin-left:' + margin + 'px;'
      }
    },
    methods: {
      loadChildren () {
        let data = {params: {'max_level': 1}}
        axios.get(this.$root.urls['load'] + this.model.id, data)
          .then(response => {
            this.$set(this.model, 'children', response.data.tree)
            this.$set(this.model, 'nb_children', response.data.tree.length)
          })
          .catch(response => {
            console.log(response)
          })
      },
      toggleChildren () {
        // Nothing to do if node has no child
        if (!this.hasChildren) {
          return
        }

        if (!this.hasLoadedChildren) {
          this.loadChildren()
        }
        this.expanded = !this.expanded
      },
      sortChildren () {
        // Sort the remaining values
        let ascDesc = !this.model.is_desc ? 1 : -1
        return this.model.children.sort((a, b) => ascDesc * a.title.localeCompare(b.title))
      },
      toggleActive () {
        this.active = !this.active
      },
      toggleSelection () {
        this.isSelected = !this.isSelected
        EventBus.$emit('select_node', this)
      },
      createNode () {
        EventBus.$emit('manager_action', this, 'create')
      },
      updateNode () {
        EventBus.$emit('manager_action', this, 'update')
      },
      deleteNode () {
        EventBus.$emit('manager_action', this, 'delete')
      },
      upload () {
        EventBus.$emit('manager_action', this, 'upload')
      },
      addChild (added) {
        this.model.children.push(added)
        this.model.nb_children += 1
      },
      removeChild (removed) {
        let idx = this.model.children.findIndex((item) => {
          return item === removed
        })
        this.model.children.splice(idx, 1)
        this.model.nb_children -= 1
      },
      startTask (type) {
        EventBus.$emit('task_started', type, this.model)
        let status = 'STARTED'
        switch (type) {
          case 'parse':
            this.model.parse_status = status
            break
          case 'index':
            this.model.index_status = status
            break
          case 'off':
            this.model.pack_status = status
            break
        }
      },
      createPublication () {
        // Open modal to complete information and create a publication from this folder
        EventBus.$emit('manager_action', this, 'index')
      },
      updateCanIndex () {
        this.$set(this.$parent.model, 'can_index', false)
      }
    },
    created () {
      EventBus.$on('select_node', (node) => {
        if (this !== node && this.isSelected) {
          this.isSelected = false
        }
      })

      EventBus.$on('refresh_node_button', (id, type, status, reloadChildren, newIndex) => {
        // Concern this node
        if (this.model.id === id) {
          switch (type) {
            case 'P':
              this.model.parse_status = status
              if (newIndex) {
                this.model.index_status = 'TODO'
              }
              break
            case 'I':
              this.model.index_status = status
              break
            case 'O':
              this.model.pack_status = status
              break
          }

          if (reloadChildren) {
            this.loadChildren()
          }
        }
      })

      EventBus.$on('task_needed', (type, id) => {
        if (this.model.id === id) {
          switch (type) {
            case 'parse':
              this.model.parse_status = 'TODO'
              break
            case 'index':
              this.model.index_status = 'TODO'
              break
          }
        }
      })
    },
    mounted () {
      this.expanded = this.hasLoadedChildren
    }
  }
</script>

<style lang="scss" scoped>
  .collection-node {
    padding: 5px 0;
    margin: 0 0 -1px 0;
    border-bottom: 1px solid #ccc;
    border-top: 1px solid #ccc;
  }

  .collection-info {
    padding-left: 0;
  }

  a,
  .treeview-toggle {
    cursor: pointer;
  }

  .active {
    background-color: #eee;
  }

  .not-published {
    font-style: italic;
  }

  .selected {
    /* color: #1B7EAC; */
    /*background-color: #bbbbbb;*/
  }

  .active .glyphicon-sort {
    padding-right: 0;
    cursor: grab;
  }

  .to-click {
    cursor: pointer;
  }
</style>

