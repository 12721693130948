var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "modal",
    { attrs: { id: "field-editor", data: _vm.modalData, native: true } },
    [
      _c("div", { attrs: { slot: "header" }, slot: "header" }, [
        _vm.isAdding
          ? _c("h3", { staticClass: "modal-title" }, [
              _vm._v("Add a new field"),
            ])
          : !_vm.deleteFlag
          ? _c("h3", { staticClass: "modal-title" }, [
              _vm._v("Edit field " + _vm._s(_vm.fieldName)),
            ])
          : _c("h3", { staticClass: "modal-title" }, [
              _vm._v("Delete field " + _vm._s(_vm.fieldName)),
            ]),
      ]),
      _vm._v(" "),
      _c("div", { attrs: { slot: "body" }, slot: "body" }, [
        _c("form", { attrs: { id: "field-editor-form" } }),
      ]),
      _vm._v(" "),
      _c("div", { attrs: { slot: "footer" }, slot: "footer" }, [
        _c(
          "button",
          {
            staticClass: "btn btn-default",
            attrs: { type: "button" },
            on: {
              click: function ($event) {
                $event.preventDefault()
                _vm.submit()
              },
            },
          },
          [_vm._v(_vm._s(_vm.deleteFlag ? "Delete" : "Save"))]
        ),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "btn btn-outline-info",
            attrs: { "data-dismiss": "modal", "data-action": "cancel" },
          },
          [_vm._v("Cancel")]
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }