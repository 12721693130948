<template>
<transition name="modal">
 <div :class="{'modal': true, 'modal-mask': !native, 'fade': native, 'non-native': !native}">
  <div class="modal-dialog  modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <slot name="header"></slot>
      </div>

      <div class="modal-body">
        <div v-show="data.errorMsg" class="alert alert-danger">
          {{ data.errorMsg }}
        </div>
        <slot name="body"></slot>
      </div>

      <div class="modal-footer">
        <slot name="footer"></slot>
      </div>
    </div>
  </div>
</div>
</transition>
</template>

<script>
  // import $ from 'jquery'

  export default {
    name: 'modal',
    props: ['data', 'native'],
    mounted () {
      // $(this.$el).on('shown.bs.modal', () => {
      //   if (typeof this.$parent.loadFormEditor === 'function') {
      //     this.$parent.loadFormEditor()
      //   }
      // })
    }
  }
</script>

<style lang="scss" scoped>
  .modal {
    text-align: center;
    padding: 0 !important;
  }

  .modal::before {
    content: '';
    display: inline-block;
    height: 100%;
    vertical-align: middle;
    margin-right: -4px;
  }

  .modal-dialog {
    display: inline-block;
    text-align: left;
    vertical-align: middle;
  }

  .modal-mask {
    z-index: 1999;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: table;
    transition: opacity 0.3s ease;
  }

  .modal-header {
      background: #f5f5f5;
      border-bottom: 1px solid #ccc;
      box-sizing: border-box;
      height: 56px;
      margin: 0;
      overflow: hidden;
      padding: 15px 20px;
      position: relative;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

  .modal-body {
    height:500px;
    overflow:auto;
  }

  .modal-footer {
      background: #f5f5f5;
      box-sizing: border-box;
      border-top: 1px solid #ccc;
      clear: both;
      height: 51px;
      overflow: hidden;
      padding: 7px 10px;
      text-align: right;
      white-space: nowrap;
      width: 100%;
    }

    .modal-footer .btn {
      background: #1b7eac;
      border-color: transparent;
      color: #fff;
      font-weight: bold;
    }

    .modal-footer a {
      cursor: pointer;
      font-size: 14px;
      display: inline-block;
      padding: 0 10px;
      vertical-align: baseline;
      color: #1b7eac;
      line-height: 34px;
    }

  .non-native {
    display: block
  }
</style>
