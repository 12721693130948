var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "button",
        {
          staticClass: "btn btn-default",
          attrs: { type: "button" },
          on: {
            click: function ($event) {
              _vm.sync()
            },
          },
        },
        [_vm._v(_vm._s(_vm.$t("sync:btn")))]
      ),
      _c("br"),
      _c("br"),
      _vm._v(" "),
      _c("div", { staticClass: "panel panel-default" }, [
        _vm._m(0),
        _vm._v(" "),
        _c("div", { staticClass: "panel-body" }, [
          _c(
            "div",
            { staticClass: "col-tree", attrs: { id: "collection-manager" } },
            [
              _c("div", { staticClass: "row" }, [
                _c("th", { staticClass: "col-md-7" }, [
                  _vm._v(_vm._s(_vm.$t("collections:table:name"))),
                ]),
                _vm._v(" "),
                _c("th", { staticClass: "col-md-3" }, [
                  _vm._v(_vm._s(_vm.$t("collections:table:run"))),
                ]),
                _vm._v(" "),
                _c("th", { staticClass: "col-md-2" }, [
                  _vm._v(_vm._s(_vm.$t("collections:table:actions"))),
                ]),
              ]),
              _vm._v(" "),
              _vm._l(_vm.collections, function (item) {
                return _c("collection-node", {
                  key: item.id,
                  staticClass: "item",
                  attrs: { model: item, depth: 0 },
                })
              }),
            ],
            2
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "panel panel-default" }, [
        _c("div", { staticClass: "panel-heading" }, [
          _c("span", {
            staticClass: "glyphicon glyphicon-hourglass",
            attrs: { "aria-hidden": "true" },
          }),
          _vm._v(_vm._s(_vm.$t("panel:tasks"))),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "panel-body" }, [_c("task-manager")], 1),
      ]),
      _vm._v(" "),
      _vm.showModal
        ? _c(
            "modal",
            { attrs: { id: "colEditorModal", data: _vm.modalData } },
            [
              _c("div", { attrs: { slot: "header" }, slot: "header" }, [
                _c(
                  "h3",
                  { staticClass: "modal-title" },
                  [_vm._t("title", [_vm._v(_vm._s(_vm.modalData.title))])],
                  2
                ),
              ]),
              _vm._v(" "),
              _c("div", { attrs: { slot: "body" }, slot: "body" }, [
                _c("form", { attrs: { id: "col-form", novalidate: "" } }, [
                  _c("div", { domProps: { innerHTML: _vm._s(_vm.content) } }),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { attrs: { slot: "footer" }, slot: "footer" }, [
                _vm.isDeleting
                  ? _c(
                      "button",
                      {
                        staticClass: "btn btn-default",
                        attrs: { type: "button", "data-dismiss": "modal" },
                        on: { click: _vm.submit },
                      },
                      [_vm._v(_vm._s(_vm.$t("modal:button:delete")))]
                    )
                  : _c(
                      "button",
                      {
                        staticClass: "btn btn-default",
                        attrs: { type: "button", "data-dismiss": "modal" },
                        on: { click: _vm.submit },
                      },
                      [_vm._v(_vm._s(_vm.$t("modal:button:save")))]
                    ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-outline-info",
                    attrs: { type: "button" },
                    on: {
                      click: function ($event) {
                        _vm.toggleModal()
                      },
                    },
                  },
                  [_vm._v(_vm._s(_vm.$t("modal:button:cancel")))]
                ),
              ]),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "panel-heading" }, [
      _c("span", {
        staticClass: "glyphicon glyphicon-book",
        attrs: { "aria-hidden": "true" },
      }),
      _vm._v(" Collections"),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }