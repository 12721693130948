<i18n>
{
  "en": {
    "modal:title:new_collection": "Create new collection under ",
    "modal:title:edit_collection": "Update ",
    "modal:title:del_collection": "Delete collection",
    "modal:title:del_document": "Delete document",
    "modal:title:upload": "Upload content under ",
    "modal:title:index": "Create index for ",
    "modal:message:del_collection": "Are you sure you want to delete",
    "modal:button:cancel": "Cancel",
    "modal:button:save": "Save",
    "modal:button:delete": "Delete",
    "panel:tasks": "Tasks",
    "collections:table:name": "Name",
    "collections:table:run": "Parse & Index",
    "collections:table:actions": "Actions",
    "errors:not_found": "Page not found",
    "sync:btn": "Synchronize shared folder"
  },
  "fr": {
    "modal:title:new_collection": "Créer une nouvelle collection dans ",
    "modal:title:edit_collection": "Mettre à jour ",
    "modal:title:del_collection": "Supprimer la collection",
    "modal:title:del_document": "Supprimer le document",
    "modal:title:upload": "Transférer du contenu dans ",
    "modal:title:index": "Créer un index pour ",
    "modal:message:del_collection": "Voulez-vous vraiment supprimer",
    "modal:button:cancel": "Annuler",
    "modal:button:save": "Sauvegarder",
    "modal:button:delete": "Supprimer",
    "panel:tasks": "Tâches",
    "collections:table:name": "Titre",
    "collections:table:run": "Analyse & Indexation",
    "collections:table:actions": "Actions",
    "errors:not_found": "Page introuvable",
    "sync:btn": "Synchronizer le répertoire partagé"
  }
}
</i18n>

<template>
  <div>
    <!-- Synchronize button-->
    <button type="button" class="btn btn-default" @click="sync()">{{ $t('sync:btn') }}</button><br><br>

    <!--Collections tree-->
    <div class="panel panel-default">
      <div class="panel-heading"><span class="glyphicon glyphicon-book" aria-hidden="true"></span> Collections</div>
      <div class="panel-body">
        <div id="collection-manager" class="col-tree">
          <div class="row">
            <th class="col-md-7">{{ $t('collections:table:name') }}</th>
            <th class="col-md-3">{{ $t('collections:table:run') }}</th>
            <th class="col-md-2">{{ $t('collections:table:actions') }}</th>
          </div>

          <collection-node class="item" :model="item" :depth=0 v-for="item in collections" :key="item.id"></collection-node>
        </div>
      </div>
    </div>

    <!--Tasks list-->
    <div class="panel panel-default">
      <div class="panel-heading"><span class="glyphicon glyphicon-hourglass" aria-hidden="true"></span>{{ $t('panel:tasks') }}</div>
      <div class="panel-body">
        <task-manager></task-manager>
      </div>
    </div>

    <!--Modal for action-->
    <modal id="colEditorModal" v-if="showModal" :data="modalData">
      <div slot="header">
        <h3 class="modal-title">
          <slot name="title">{{modalData.title}}</slot>
        </h3>
      </div>

      <div slot="body">
        <form id="col-form" novalidate>
          <div v-html="content"></div>
        </form>
      </div>

      <div slot="footer">
        <button type="button" class="btn btn-default" data-dismiss="modal" v-if="isDeleting" @click="submit">{{ $t('modal:button:delete') }}</button>
        <button type="button" class="btn btn-default" data-dismiss="modal" v-else @click="submit">{{ $t('modal:button:save') }}</button>
        <button type="button" class="btn btn-outline-info" @click="toggleModal()">{{ $t('modal:button:cancel') }}</button>
      </div>
    </modal>
  </div>
</template>

<script>
  import $ from 'jquery'
  import CollectionNode from './collection-node.vue'
  import TaskManager from './task-manager.vue'
  import EventBus from './event-bus'
  import Modal from './modal.vue'
  import 'devbridge-autocomplete'

  import axios from 'axios'

  export default {
    name: 'collection-manager',
    components: {CollectionNode, Modal, TaskManager},
    data: function () {
      return {
        collections: Array,
        action: undefined,
        showModal: false,
        modalData: Array,
        curNode: undefined,
        content: undefined
      }
    },
    watch: {
      'content': function (val, oldVal) {
        if (val !== oldVal) {
          this.$nextTick(function () {
            let elFile = $('#id_os_path')
            if (elFile) {
              elFile.autocomplete({
                serviceUrl: elFile.data('url'),
                minChars: 0,
                zIndex: 100100
              })
            }
          })
        }
      }
    },
    computed: {
      isDeleting () {
        return this.action === 'delete'
      }
    },
    methods: {
      loadCollections (nbLevels) {
        let data = {params: {'max_level': nbLevels}}
        axios.get(this.$root.urls['load'] + '0', data)
          .then(response => {
            this.collections = response.data.tree
          })
          .catch(response => {
            console.log(response)
          })
      },
      toggleModal () {
        // Modal will be hidden: Reset state
        if (this.showModal) {
          this.action = undefined
          this.modalData.errorMsg = undefined
          this.content = undefined
          this.curNode = undefined
        } else {
          switch (this.action) {
            case 'delete':
              if (this.curNode.isDocument) {
                this.modalData.title = this.$t('modal:title:del_document')
              } else {
                this.modalData.title = this.$t('modal:title:del_collection')
              }
              this.content = '<div class="alert alert-warning" role="alert"><p>' + this.$t('modal:message:del_collection') + ' <b>' +
                this.curNode.model.title + '</b>?</p></div>'
              break
            case 'update':
              this.modalData.title = this.$t('modal:title:edit_collection') + this.curNode.model.title
              break
            case 'create':
              this.modalData.title = this.$t('modal:title:new_collection') + this.curNode.model.title
              break
            case 'upload':
              this.modalData.title = this.$t('modal:title:upload') + this.curNode.model.title
              break
            case 'index':
              this.modalData.title = this.$t('modal:title:index') + this.curNode.model.title
              break
          }
        }
        this.showModal = !this.showModal
      },
      submit () {
        switch (this.action) {
          case 'delete':
            this.deleteNode()
            break
          case 'update':
            this.updateNode()
            break
          case 'create':
            this.createNode()
            break
          case 'upload':
            this.uploadContent()
            break
          case 'index':
            this.createPublication()
            break
        }
      },
      sync () {
        let url = this.$root.urls['tasks'].replace('tasks/', 'admin/sync/')

        axios.get(url).then(response => {
          // switch (response.status) {
          //   default:
          //     break
          // }
        }).catch(response => {
          console.log(response)
        })
      },
      getForm () {
        let url
        switch (this.action) {
          case 'create':
            url = this.$root.urls['update']
            break
          default:
            url = this.$root.urls[this.action] + this.curNode.model.id + '/'
        }

        this.$nextTick(function () {
          $(document).on('shown.bs.tab', function (e) {
            // e.target // newly activated tab
            // e.relatedTarget // previous active tab

            let el = $('.datepicker')
            if (el.length > 0) {
              el.datepicker()
              // .mask('9999-99-99', {})
              el.attr('placeholder', 'YYYY-MM-DD')
              el.removeClass('datepicker')
            }
          })
        })

        axios.get(url).then(response => {
          switch (response.status) {
            case 404:
              this.content = '</br><h1>Page not found</h1></br>'
              break
            default:
              this.content = response.data
              break
          }
        }).catch(response => {
          console.log(response)
        })
      },
      createNode () {
        let formElement = document.querySelector('#col-form')
        let data = new window.FormData(formElement)
        data.set('parent', this.curNode.model.id)

        axios.post(this.$root.urls['update'], data,
          {
            'headers': {
              'Content-Type': 'application/json'
            }
          }
        ).then(response => {
          switch (response.status) {
            case 404:
              this.content = '</br><h1>Page not found</h1></br>'
              break
            case 201:
              this.curNode.model.children.push(response.data['has_changed'])
              this.curNode.model.nb_children += 1
              this.toggleModal()
              break
            default:
              this.content = response.data
          }
        }).catch(response => {
          console.log(response)
          this.modalData.errorMsg = 'Unable to create "' + data.get('title') + '"'
        })
      },
      updateNode () {
        let formElement = document.querySelector('#col-form')
        let data = new window.FormData(formElement)
        let url = this.$root.urls['update'] + this.curNode.model.id + '/'
        axios.post(url, data,
          {
            'headers': {
              'Content-Type': 'application/json'
            }
          }).then(response => {
          switch (response.status) {
            case 404:
              this.content = '</br><h1>' + this.$t('error:not_found') + '</h1></br>'
              break
            case 201:
              for (let key in response.data['has_changed']) {
                this.$set(this.curNode.model, key, response.data['has_changed'][key])
              }
              if (response.data['refresh']['parse'] !== undefined) {
                EventBus.$emit('task_needed', 'parse', response.data['refresh']['parse'])
              }
              if (response.data['refresh']['index'] !== undefined) {
                EventBus.$emit('task_needed', 'index', response.data['refresh']['index'])
              }
              this.toggleModal()
              break
            default:
              this.content = response.data
          }
        }).catch(error => {
          console.log(error.response)
          this.modalData.errorMsg = 'Unable to update "' + this.curNode.model.title + '"'
        })
      },
      deleteNode () {
        let url = this.$root.urls['delete'] + this.curNode.model.id + '/'
        axios.delete(url,
          {}
        ).then(response => {
          switch (response.status) {
            case 202:
              this.modalData.errorMsg = response.data['error']
              this.content = ''
              break
            default:
              this.curNode.$parent.removeChild(this.curNode.model)
              this.curNode = undefined
              if (response.data['refresh'] !== undefined && response.data['refresh']['parse'] !== undefined) {
                EventBus.$emit('task_needed', 'parse', response.data['refresh']['parse'])
              }
              this.toggleModal()
          }
        }).catch(response => {
          console.log(response)
          this.modalData.errorMsg = 'Unable to delete "' + this.curNode.model.title + '"'
        })
      },
      uploadContent () {
        let formElement = document.querySelector('#col-form')
        let data = new window.FormData(formElement)
        let url = this.$root.urls['upload'] + this.curNode.model.id + '/'
        axios.post(url, data,
          {
            'headers': {
              'Content-Type': 'application/json'
            }
          }).then(response => {
          switch (response.status) {
            case 404:
              this.content = '</br><h1>' + this.$t('error:not_found') + '</h1></br>'
              break
            case 201:
              this.curNode.model.children.push(response.data)
              this.curNode.model.nb_children += 1
              EventBus.$emit('task_started', 'parse', this.curNode.model.children[this.curNode.model.children.length - 1], true)
              this.toggleModal()
              break
            default:
              this.content = response.data
          }
        }).catch(error => {
          console.log(error.response)
          this.modalData.errorMsg = 'Unable to update "' + this.curNode.model.title + '"'
        })
      },
      createPublication () {
        let formElement = document.querySelector('#col-form')
        let data = new window.FormData(formElement)
        let url = this.$root.urls['index'] + this.curNode.model.id + '/'
        axios.post(url, data,
          {
            'headers': {
              'Content-Type': 'application/json'
            }
          }).then(response => {
          switch (response.status) {
            case 404:
              this.content = '</br><h1>' + this.$t('error:not_found') + '</h1></br>'
              break
            case 201:
              this.curNode.model = Object.assign(this.curNode.model, {
                code: response.data['code'],
                title: response.data['title'],
                description: response.data['description'],
                can_index: response.data['can_index'],
                index_task: response.data['index_task'],
                index_status: response.data['index_status']
              })
              this.curNode.updateCanIndex()
              EventBus.$emit('task_started', 'index', this.curNode.model, true)
              this.toggleModal()
              break
            default:
              this.content = response.data
          }
        }).catch(error => {
          console.log(error.response)
          this.modalData.errorMsg = 'Unable to index "' + this.curNode.model.title + '"'
        })
      }
    },
    created () {
      EventBus.$on('manager_action', (node, action) => {
        this.action = action
        this.curNode = node
        if (action !== 'delete') {
          this.getForm()
        }
        this.toggleModal()
      })
    },
    beforeMount () {
      // Save used urls
      this.$root.urls = {
        'load': this.$root.$el.getAttribute('data-get-children-url').replace('/0/', '/'),
        'update': this.$root.$el.getAttribute('data-update-url').replace('/0/', '/'),
        'delete': this.$root.$el.getAttribute('data-delete-url').replace('/0/', '/'),
        'upload': this.$root.$el.getAttribute('data-upload-url').replace('/0/', '/'),
        'tasks': this.$root.$el.getAttribute('data-tasks-url'),
        'parse': this.$root.$el.getAttribute('data-parse-url').replace('/0/', '/'),
        'index': this.$root.$el.getAttribute('data-index-url').replace('/0/', '/')
      }
    },
    mounted () {
      this.$root.manager = this

      // Get data
      this.loadCollections(3)

      $(document).on('click', '#newField', function () {
        EventBus.$emit('addField', $(this).data('index'))
      })
      $(document).on('click', '#editField', function () {
        EventBus.$emit('editField', $(this).data('index'), $(this).data('field'))
      })
      $(document).on('click', '#deleteField', function () {
        EventBus.$emit('deleteField', $(this).data('index'), $(this).data('field'))
      })

      document.addEventListener('keydown', e => {
        if (this.showModal) {
          if (e.keyCode === 13) {
            this.submit()
            e.preventDefault()
          }

          if (e.keyCode === 27) {
            this.toggleModal()
            e.preventDefault()
          }
        }
      }, false)
    }
  }
</script>

<style lang="scss" scoped>
  .col-tree {
    padding: 0;
  }
</style>
