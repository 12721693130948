var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isShown,
              expression: "isShown",
            },
          ],
          class: {
            active: _vm.active,
            selected: _vm.isSelected,
            "collection-node": true,
            row: true,
          },
          on: {
            click: _vm.toggleSelection,
            dblclick: _vm.updateNode,
            mouseenter: _vm.toggleActive,
            mouseleave: _vm.toggleActive,
          },
        },
        [
          _c(
            "span",
            { staticClass: "col-md-7 collection-info", style: _vm.indent },
            [
              _c("span", {
                staticClass: "treeview-toggle",
                class: _vm.hasChildren
                  ? _vm.expanded
                    ? "glyphicon glyphicon-chevron-down"
                    : "glyphicon glyphicon-chevron-right"
                  : "",
                on: { click: _vm.toggleChildren },
              }),
              _vm._v(" "),
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.isFolder,
                      expression: "isFolder",
                    },
                  ],
                  staticClass: "label label-default collection-type",
                },
                [_vm._v("DIR")]
              ),
              _vm._v(" "),
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.isPublication,
                      expression: "isPublication",
                    },
                  ],
                  staticClass: "label label-primary collection-type",
                },
                [_vm._v("PUB")]
              ),
              _vm._v(" "),
              _c(
                "span",
                {
                  class: {
                    "collection-title": true,
                    "not-published": _vm.isNotPublished,
                    obsolete: _vm.isObsolete,
                  },
                },
                [_vm._v(_vm._s(_vm.model.title))]
              ),
              _vm._v(" "),
              _vm.hasChildren && !_vm.expanded
                ? _c("span", { staticClass: "badge" }, [
                    _vm._v(_vm._s(_vm.nbChildren)),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.isDesc && !_vm.isDocument
                ? _c("span", {
                    staticClass: "glyphicon glyphicon-sort-by-alphabet-alt",
                    attrs: { title: "Specific configuration" },
                  })
                : _vm._e(),
            ]
          ),
          _vm._v(" "),
          _c("span", { staticClass: "col-md-3" }, [
            _c(
              "span",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.canParse,
                    expression: "canParse",
                  },
                ],
                attrs: { id: "btnParseStatus" + _vm.model.id },
              },
              [
                _vm.isParsing
                  ? _c(
                      "button",
                      {
                        staticClass: "btn btn-xs btn-warning disabled",
                        attrs: { type: "button" },
                      },
                      [_vm._v(_vm._s(_vm.$t("run:parsing")))]
                    )
                  : _vm.toParse
                  ? _c(
                      "button",
                      {
                        staticClass: "btn btn-xs btn-danger",
                        attrs: { type: "button" },
                        on: {
                          click: function ($event) {
                            _vm.startTask("parse")
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.$t("run:parse")))]
                    )
                  : _c(
                      "button",
                      {
                        staticClass: "btn btn-xs btn-success",
                        attrs: { type: "button" },
                        on: {
                          click: function ($event) {
                            _vm.startTask("parse")
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.$t("run:parse")))]
                    ),
              ]
            ),
            _vm._v(" "),
            _c(
              "span",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.canIndex,
                    expression: "canIndex",
                  },
                ],
                attrs: { id: "btnIndexStatus" + _vm.model.id },
              },
              [
                _vm.isIndexing
                  ? _c(
                      "button",
                      {
                        staticClass: "btn btn-xs btn-warning disabled",
                        attrs: { type: "button" },
                      },
                      [_vm._v(_vm._s(_vm.$t("run:indexing")))]
                    )
                  : _vm.toIndex
                  ? _c(
                      "button",
                      {
                        staticClass: "btn btn-xs btn-danger",
                        attrs: { type: "button" },
                        on: {
                          click: function ($event) {
                            _vm.startTask("index")
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.$t("run:index")))]
                    )
                  : _vm.hasIndex
                  ? _c(
                      "button",
                      {
                        staticClass: "btn btn-xs btn-success",
                        attrs: { type: "button" },
                        on: {
                          click: function ($event) {
                            _vm.startTask("index")
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.$t("run:index")))]
                    )
                  : _vm.canIndex
                  ? _c(
                      "button",
                      {
                        staticClass: "btn btn-xs btn-default",
                        attrs: { type: "button" },
                        on: { click: _vm.createPublication },
                      },
                      [_vm._v(_vm._s(_vm.$t("run:create_index")))]
                    )
                  : _vm._e(),
              ]
            ),
            _vm._v(" "),
            _c(
              "span",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.canPack,
                    expression: "canPack",
                  },
                ],
                attrs: { id: "btnPackStatus" + _vm.model.id },
              },
              [
                _vm.isPacking
                  ? _c(
                      "button",
                      {
                        staticClass: "btn btn-xs btn-warning disabled",
                        attrs: { type: "button" },
                      },
                      [_vm._v(_vm._s(_vm.$t("run:packing")))]
                    )
                  : _vm.toPack
                  ? _c(
                      "button",
                      {
                        staticClass: "btn btn-xs btn-danger",
                        attrs: { type: "button" },
                        on: {
                          click: function ($event) {
                            _vm.startTask("off")
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.$t("run:pack")))]
                    )
                  : _c(
                      "button",
                      {
                        staticClass: "btn btn-xs btn-success",
                        attrs: { type: "button" },
                        on: {
                          click: function ($event) {
                            _vm.startTask("off")
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.$t("run:pack")))]
                    ),
              ]
            ),
          ]),
          _vm._v(" "),
          _c("span", { staticClass: "col-md-2" }, [
            _c(
              "a",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.isCollection,
                    expression: "isCollection",
                  },
                ],
                on: { click: _vm.createNode },
              },
              [
                _c("span", {
                  staticClass: "glyphicon glyphicon-plus",
                  attrs: { title: "Add new child category" },
                }),
              ]
            ),
            _vm._v(" "),
            _c("a", { on: { click: _vm.updateNode } }, [
              _c("span", {
                staticClass: "glyphicon glyphicon-edit",
                attrs: { title: "Edit" },
              }),
            ]),
            _vm._v(" "),
            _c(
              "a",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.isCollection & !_vm.hasChildren,
                    expression: "isCollection & !hasChildren",
                  },
                ],
                on: { click: _vm.deleteNode },
              },
              [
                _c("span", {
                  staticClass: "glyphicon glyphicon-trash",
                  attrs: { title: "Delete category (if empty)" },
                }),
              ]
            ),
            _vm._v(" "),
            _c(
              "a",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.isCollection,
                    expression: "isCollection",
                  },
                ],
                on: { click: _vm.upload },
              },
              [
                _c("span", {
                  staticClass: "glyphicon glyphicon-import",
                  attrs: { title: "Add content in this category" },
                }),
              ]
            ),
            _vm._v(" "),
            _c(
              "a",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.isDocument | _vm.isMaster,
                    expression: "isDocument | isMaster",
                  },
                ],
                on: { click: _vm.deleteNode },
              },
              [
                _c("span", {
                  staticClass: "glyphicon glyphicon-trash",
                  attrs: { title: "Delete document" },
                }),
              ]
            ),
          ]),
        ]
      ),
      _vm._v(" "),
      _vm._l(_vm.model.children, function (node) {
        return _vm.hasLoadedChildren
          ? _c("collection-node", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.expanded,
                  expression: "expanded",
                },
              ],
              key: node.id,
              attrs: { model: node, depth: _vm.depth + 1 },
            })
          : _vm._e()
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }