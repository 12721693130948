import 'formdata-polyfill'
import './jquery.formset'
import './publication'

import Vue from 'vue'
import VueI18n from 'vue-i18n'
import vueCustomElement from 'vue-custom-element'

import CollectionManager from './collection-manager.vue'
import FieldEditor from './field-editor.vue'

import mixins from './mixins'
import axios from 'axios'
import './field-form'
import { loadProgressBar } from 'axios-progress-bar'
import '../frontend/css/nprogress.css'

Vue.use(VueI18n)
Vue.use(vueCustomElement)
Vue.mixin(mixins)

export const BASE_URL = document.querySelector('meta[name="base-url"]').getAttribute('content')

const i18n = new VueI18n({
  locale: document.querySelector('html').getAttribute('lang') || 'en'
})

axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'
axios.defaults.headers.post['X-CSRFToken'] = document.querySelector('meta[name="csrf-token"]').getAttribute('content')
axios.defaults.headers.delete['X-CSRFToken'] = document.querySelector('meta[name="csrf-token"]').getAttribute('content')

loadProgressBar({}, axios)

// Component for collections list
let el = document.querySelector('#collections')
if (el) {
  const vue = new Vue({ // eslint-disable-line
    el,
    components: {CollectionManager},
    data: function () {
      return {
        base_url: BASE_URL.replace('list/', ''),
        manager: undefined,
        urls: Object
      }
    },
    render: function (h) {
      return h('CollectionManager')
    },
    i18n
  })
}

Vue.customElement('pub-field-editor', FieldEditor)
