import $ from 'jquery'

function updateSearchPanel () {
  let active = $('#field-editor-form #id_in_search').get(0).checked
  $('#search-settings-panel').toggle(active)
}

function updateResultPanel () {
  let active = $('#field-editor-form #id_in_result').get(0).checked
  $('#field-form-result-panel').toggle(active)
}

function updateCommentPanel () {
  let active = $('#field-editor-form #id_in_comment_overview').get(0).checked
  $('#field-form-comment-panel').toggle(active)
}

function updateAllPanels () {
  updateSearchPanel()
  updateResultPanel()
  updateCommentPanel()
}

let $fieldSetting = $('#field-form-settings-loaded')
if ($fieldSetting.length) {
  updateAllPanels()
}

let $body = $('body')
// Obsolete and not working in IE11
// $body.on('DOMNodeInserted', '#field-form-settings-loaded', function (e) {
//   console.log('dom insert')
//   updateSearchPanel()
//   updateResultPanel()
//   updateCommentPanel()
// })

$body.on('click', '#field-editor-form #id_in_search', function (e) {
  updateSearchPanel()
})

$body.on('click', '#field-editor-form #id_in_result', function (e) {
  updateResultPanel()
})

$body.on('click', '#field-editor-form #id_in_comment_overview', function (e) {
  updateCommentPanel()
})
