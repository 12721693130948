<template>
  <!--Modal for field form-->
  <modal id="field-editor" :data="modalData" :native="true">
    <div slot="header">
      <h3 class="modal-title" v-if="isAdding">Add a new field</h3>
      <h3 class="modal-title" v-else-if="!deleteFlag">Edit field {{ fieldName }}</h3>
      <h3 class="modal-title" v-else>Delete field {{ fieldName }}</h3>
    </div>

    <div slot="body">
      <form id="field-editor-form"></form>
    </div>

    <div slot="footer">
      <button type="button" class="btn btn-default" @click.prevent="submit()">{{deleteFlag ? 'Delete' : 'Save'}}</button>
      <button data-dismiss="modal" data-action="cancel" class="btn btn-outline-info">Cancel</button>
    </div>
  </modal>
</template>

<script>
  import Modal from './modal.vue'
  import $ from 'jquery'
  import axios from 'axios'
  import path from 'path'

  let fieldEditor = {
    name: 'field-editor',
    components: {Modal},
    props: {
      'field': {
        type: Number,
        default: -1
      },
      url: {
        type: String,
        required: true
      },
      deleteFlag: Boolean
    },
    data: function () {
      return {
        fieldName: String,
        modalData: Array
      }
    },
    computed: {
      isAdding () {
        return this.field === -1
      }
    },
    methods: {
      getUrl () {
        if (this.isAdding) {
          return String(this.url)
        } else {
          if (this.deleteFlag) {
            return path.join(String(this.url).replace('/new/', '/delete/'), String(this.field), '/')
          } else {
            return path.join(String(this.url).replace('/new/', '/edit/'), String(this.field), '/')
          }
        }
      },
      updateFormEditor (html) {
        let $el = document.getElementById('field-editor-form')
        $el.innerHTML = html
      },
      submit () {
        let data = new window.FormData(document.getElementById('field-editor-form'))
        data.set('index', this.index)

        axios.post(this.getUrl(), data).then(response => {
          switch (response.status) {
            case 201:
              let data = response.data
              // New field created
              if (this.isAdding) {
                let noField = document.getElementById('no-field-tr')
                if (noField) {
                  noField.parentNode.removeChild(noField)
                }
                let tableRef = document.getElementById('fields-table').getElementsByTagName('tbody')[0]
                // Insert a row in the table at the last row
                let newRow = tableRef.insertRow(tableRef.rows.length)
                let onClickEdit = "document.querySelector('pub-field-editor').field = " + data.id + ";document.querySelector('pub-field-editor').deleteFlag = 'false'"
                let onClickDel = "document.querySelector('pub-field-editor').field = " + data.id + ";document.querySelector('pub-field-editor').deleteFlag = 'true'"
                newRow.innerHTML = '<tr id="row-field-' + data.id + '">\n' +
                  '<td id="name-field-' + data.id + '">' + data.name + '</td><td>\n' +
                  '<a id="editField" style="cursor: pointer;" data-toggle="modal" href="#field-editor" onclick="' + onClickEdit + '">\n' +
                  '<span class="glyphicon glyphicon-pencil"></span></a>\n' +
                  '<a id="deleteField" style="cursor: pointer;" data-toggle="modal" href="#field-editor" onclick="' + onClickDel + '">\n' +
                  '<span class="glyphicon glyphicon-trash"></span></a>\n' +
                  '</td>\n' +
                  '</tr>'
              } else {
                // Edit existing field
                let $el = document.getElementById('name-field-' + data.id)
                $el.innerHTML = data.name
              }
              this.close()
              break
            // Successful delete
            case 204:
              let $el = document.getElementById('row-field-' + String(this.field))
              $el.parentNode.removeChild($el)
              this.close()
              break
            default:
              this.updateFormEditor(response.data)
          }
        }).catch(response => {
          console.error('DEBUG', response)
        })
      },
      close () {
        $(this.$el).modal('hide')
      },
      loadFormEditor () {
        if (this.deleteFlag) {
          let msgBoxContent = '<div class="alert alert-warning" role="alert"><p>Are you sure you want to delete <b>' +
                                    this.fieldName + '</b>?</p></div>'
          this.updateFormEditor(msgBoxContent)
        } else {
          axios.get(this.getUrl())
            .then(response => {
              this.updateFormEditor(response.data)
            })
            .catch(function (error) {
              console.log('axios', error)
            })
        }
      }
    },
    mounted () {
      $(this.$el).on('show.bs.modal', () => {
        let $el = document.getElementById('name-field-' + this.field)
        if ($el !== null) {
          this.fieldName = document.getElementById('name-field-' + this.field).innerText
        }
        this.loadFormEditor()
      })
    }
  }

  export default fieldEditor
</script>

<style scoped>
  form label {
    word-wrap: break-word;
    color: #707070;
    font-weight: normal;
    font-size: 12px;
  }
</style>
