var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "modal" } }, [
    _c(
      "div",
      {
        class: {
          modal: true,
          "modal-mask": !_vm.native,
          fade: _vm.native,
          "non-native": !_vm.native,
        },
      },
      [
        _c(
          "div",
          {
            staticClass: "modal-dialog  modal-lg",
            attrs: { role: "document" },
          },
          [
            _c("div", { staticClass: "modal-content" }, [
              _c("div", { staticClass: "modal-header" }, [_vm._t("header")], 2),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "modal-body" },
                [
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.data.errorMsg,
                          expression: "data.errorMsg",
                        },
                      ],
                      staticClass: "alert alert-danger",
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.data.errorMsg) +
                          "\n        "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _vm._t("body"),
                ],
                2
              ),
              _vm._v(" "),
              _c("div", { staticClass: "modal-footer" }, [_vm._t("footer")], 2),
            ]),
          ]
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }