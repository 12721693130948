var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "task-manager" } }, [
    _c("div", { staticClass: "row" }, [
      _c("span", { staticClass: "col-md-12" }, [
        _vm._v(
          _vm._s(_vm.getTasksNumber) +
            " " +
            _vm._s(_vm.$t("label:tasks_number")) +
            "\n    "
        ),
        _vm.hasTasks
          ? _c("span", { staticClass: "pull-right" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-xs btn-primary",
                  on: {
                    click: function ($event) {
                      _vm.startAll("P")
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("btn:label:start_all_parse")))]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "btn btn-xs btn-primary",
                  on: {
                    click: function ($event) {
                      _vm.startAll("I")
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("btn:label:start_all_index")))]
              ),
              _vm._v(" "),
              _c("br"),
              _c("br"),
            ])
          : _vm._e(),
      ]),
    ]),
    _vm._v(" "),
    _c(
      "ul",
      { staticClass: "list-group" },
      _vm._l(_vm.tasks, function (item, index) {
        return _c("li", { key: item.key, staticClass: "list-group-item" }, [
          _c("i", [_vm._v(_vm._s(item.started))]),
          _vm._v(" "),
          _vm.isInProgress(index)
            ? _c("span", { staticClass: "label label-warning" }, [
                _vm._v(_vm._s(item.type)),
              ])
            : _vm.toDo(index)
            ? _c("span", { staticClass: "label label-danger" }, [
                _vm._v(_vm._s(item.type)),
              ])
            : _c("span", { staticClass: "label label-success" }, [
                _vm._v(_vm._s(item.type)),
              ]),
          _vm._v("\n      " + _vm._s(item.col_title)),
          _c("span", { staticClass: "pull-right" }, [
            _vm._v("\n        [" + _vm._s(item.status) + "]\n      "),
            _vm.isInProgress(index)
              ? _c(
                  "a",
                  {
                    on: {
                      click: function ($event) {
                        _vm.startTask(index, false)
                      },
                    },
                  },
                  [_c("i", { staticClass: "glyphicon glyphicon-off" })]
                )
              : _c(
                  "a",
                  {
                    on: {
                      click: function ($event) {
                        _vm.startTask(index, true)
                      },
                    },
                  },
                  [_c("i", { staticClass: "glyphicon glyphicon-repeat" })]
                ),
          ]),
          _c("br"),
          _vm._v(" "),
          _c("div", { staticClass: "text-info" }, [
            _c("small", [_vm._v(_vm._s(item.detail))]),
          ]),
          _vm._v(" "),
          _vm.needProgressBar(index)
            ? _c("div", { staticClass: "progress" }, [
                _c(
                  "div",
                  {
                    staticClass: "progress-bar progress-bar-warning",
                    style: _vm.progressWidth(index),
                    attrs: {
                      role: "progressbar",
                      "aria-valuenow": item.progress,
                      "aria-valuemin": "0",
                      "aria-valuemax": "100",
                    },
                  },
                  [
                    _vm._v(
                      "\n          " + _vm._s(item.progress) + "%\n        "
                    ),
                  ]
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("span", { staticClass: "text-danger" }, [
            _c("small", [_vm._v(_vm._s(item.error))]),
          ]),
        ])
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }