import $ from 'jquery'
import 'devbridge-autocomplete'

$(document).on('shown.bs.tab', 'a[data-toggle="tab"]', function (e) {
  if (!$('.formset-inline').hasClass('is-formset')) {
    $('.formset-inline').formset({
      formCssClass: 'formset',
      prefix: 'field',
      addText: '<span class="col-md-12 glyphicon glyphicon-plus" aria-hidden="true"></span>',
      deleteText: '<span class="glyphicon glyphicon-minus" aria-hidden="true"></span>',
      deleteTitle: 'Delete',
      confirmDelete: false,
      minForms: 0
    })

    $('.formset-inline').addClass('is-formset')
  }
})

if ($('#choose-config')) {
  $(document).on('change', 'input[name=choose_config]', function () {
    if ($(this).val() === '0') {
      $('#id_sorting_order').prop('disabled', true)
      $('#id_obsolete').prop('disabled', true)
      $('#id_published').prop('disabled', true)
    } else {
      $('#id_sorting_order').prop('disabled', false)
      $('#id_obsolete').prop('disabled', false)
      $('#id_published').prop('disabled', false)
    }
  })
}

let elFile = $('.folder-browser')
if (elFile.length > 0) {
  elFile.autocomplete({
    serviceUrl: elFile.data('url'),
    minChars: 0
  })
}
