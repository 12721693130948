<i18n>
{
  "en": {
    "label:tasks_number": "tasks",
    "btn:label:start_all_parse": "Start all Parse",
    "btn:label:start_all_index": "Start all Index"
  },
  "fr": {
    "label:tasks_number": "tâches",
    "btn:label:start_all_parse": "Tout analyser",
    "btn:label:start_all_index": "Tout indexer"
  }
}
</i18n>

<template>
  <div id="task-manager">
    <div class="row">
      <span class="col-md-12">{{ getTasksNumber }} {{ $t('label:tasks_number') }}
      <span class="pull-right" v-if="hasTasks">
        <button class="btn btn-xs btn-primary" @click="startAll('P')">{{ $t('btn:label:start_all_parse') }}</button>
        <button class="btn btn-xs btn-primary" @click="startAll('I')">{{ $t('btn:label:start_all_index') }}</button>
        <br><br></span>
      </span>
    </div>

    <ul class="list-group">
      <li class="list-group-item" v-for="(item, index) in tasks" :key="item.key">
        <i>{{item.started}}</i>
        <span v-if="isInProgress(index)" class="label label-warning">{{item.type}}</span>
        <span v-else-if="toDo(index)" class="label label-danger">{{item.type}}</span>
        <span v-else class="label label-success">{{item.type}}</span>
        {{item.col_title}}<span class="pull-right">
          [{{item.status}}]
        <a v-if="isInProgress(index)" @click="startTask(index, false)">
        <i class="glyphicon glyphicon-off"></i>
        </a>
        <a v-else @click="startTask(index, true)">
        <i class="glyphicon glyphicon-repeat"></i>
        </a>
        </span><br>

        <div class="text-info">
          <small>{{item.detail}}</small>
        </div>
        <div class="progress" v-if="needProgressBar(index)">
          <div class="progress-bar progress-bar-warning" role="progressbar" :aria-valuenow="item.progress"
               aria-valuemin="0" aria-valuemax="100" :style="progressWidth(index)">
            {{item.progress}}%
          </div>
        </div>
        <span class="text-danger"><small>{{item.error}}</small></span>
      </li>
    </ul>
  </div>

</template>

<script>
  import EventBus from './event-bus'
  import moment from 'moment'
  import axios from 'axios'

  const axiosWithoutProgress = axios.create()
  let config = {
    onDownloadProgress: event => {
      event.preventDefault()
    },
    onUploadProgress: event => {
      event.preventDefault()
    }
  }

  axiosWithoutProgress.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'
  axiosWithoutProgress.defaults.headers.post['X-CSRFToken'] = document.querySelector('meta[name="csrf-token"]').getAttribute('content')

  let refreshDelay = 2000

  export default {
    name: 'task-manager',
    data () {
      return {
        tasks: [],
        activeNb: 0,
        checkTimer: null
      }
    },
    computed: {
      getTasksNumber () {
        return this.tasks.length
      },
      hasTasks () {
        return this.tasks.length > 1
      },
      getActiveTasks () {
        let arr = {}

        this.tasks.forEach(function (element, idx) {
          if (!element.completed && element.task_id !== null) {
            arr[idx] = element.task_id
          }
        })

        return arr
      }
    },
    methods: {
      fetchTasks () {
        let data = {}
        axios.get(this.$root.urls['tasks'], data)
          .then(response => {
            this.tasks = response.data.tasks
            this.updateActive()
          })
          .catch(response => {
            console.log(response)
          })
      },
      startAll (action) {
        for (let [idx, task] of Object.entries(this.tasks)) {
          if ((task.type === action && (task.status === 'FAILURE' || task.status === 'TODO'))) {
            this.controlTask(idx, true)
          }
        }
      },
      startTask (idx, start) {
        this.controlTask(idx, start)
        this.refreshParent(idx, false, false)
      },
      controlTask (idx, start) {
        let task = this.tasks[idx]
        let data = new window.FormData()
        data.set('pk', task.col_id)
        if (start) {
          data.set('action', task.type + '-start')
          task.completed = false
          task.status = 'PENDING'
          task.error = ''
          if (task.type === 'I') {
            task.progress = 0
            task.key = task.type + this.tasks[idx].col_id + '-' + 0
          }
        } else {
          data.set('action', task.type + '-stop')
        }

        axiosWithoutProgress.post(this.$root.urls['tasks'] + 'control/', data, config)
          .then(response => {
            task.task_id = response.data.task_id
            task.status = response.data.task_status
            this.updateActive()
          })
          .catch(response => {
            console.log(response)
          })
      },
      progressWidth (idx) {
        let task = this.tasks[idx]
        if (task === undefined) {
          return 'width: 0%;'
        }
        return 'width:' + task.progress + '%;'
      },
      exists (type, id) {
        let found = null
        for (let [idx, task] of Object.entries(this.tasks)) {
          if (type === task.type && id === task.col_id) {
            found = idx
          }
        }

        return found
      },
      updateActive () {
        let activeNb = 0
        this.tasks.forEach(function (task) {
          if ((task.status !== 'SUCCESS' && task.status !== 'FAILURE' && task.status !== 'TODO')) {
            activeNb += 1
            task.completed = false
          } else {
            task.completed = (task.status !== 'TODO' && task.status !== 'PROGRESS')
          }
        })

        this.activeNb = activeNb

        if (this.activeNb > 0 && this.checkTimer == null) {
          // Update the tasks every 2 seconds
          this.checkTimer = setInterval(() => {
            this.checkStatus()
          }, refreshDelay)
        }
        if (this.activeNb === 0 && this.checkTimer !== null) {
          clearInterval(this.checkTimer)
          this.checkTimer = null
        }
      },
      checkStatus () {
        let url = this.$root.urls['parse'] + 'status/dummy/'
        let data = this.getActiveTasks

        // Do not send request if no active task in the manager
        if (Object.keys(data).length === 0) {
          return
        }

        axiosWithoutProgress.post(url, data, config).then(response => {
          for (let [key, result] of Object.entries(response.data)) {
            let idx = Number(result['idx'])
            if (key === this.tasks[idx].task_id) {
              let oldStatus = this.tasks[idx].status
              let reloadChildren = false
              let newIndex = false
              this.tasks[idx].status = result['status']
              switch (result['status']) {
                case 'FAILURE':
                  this.tasks[idx].error = result['res']
                  break
                case 'PROGRESS':
                  switch (this.tasks[idx].type) {
                    case 'P':
                      this.$set(this.tasks[idx], 'detail', result['res']['current'])
                      this.tasks[idx].progress = 100
                      break
                    case 'I':
                      this.$set(this.tasks[idx], 'detail', result['res']['description'] + '[' + result['res']['current'] + ' / ' + result['res']['total'] + ']')
                      this.tasks[idx].progress = parseInt(parseInt(result['res']['current']) / parseInt(result['res']['total']) * 100)
                      this.tasks[idx].key = this.tasks[idx].type + this.tasks[idx].col_id + '-' + this.tasks[idx].progress
                      break
                    case 'O':
                      let temp = result['res']['description'] + '[' + result['res']['current']
                      if (result['res']['total'] !== 0) {
                        temp = temp + '/' + result['res']['total']
                      }
                      temp = temp + ']'
                      this.$set(this.tasks[idx], 'detail', temp)
                      this.tasks[idx].progress = 100
                      break
                  }
                  break
                case 'SUCCESS':
                  if (this.tasks[idx].type === 'P') {
                    reloadChildren = true
                    if (result['res'] !== 0) {
                      newIndex = true
                    }
                  }
                  this.tasks[idx].detail = null
                  this.tasks[idx].progress = 100
                  this.tasks[idx].error = ''
                  this.tasks[idx].key = this.tasks[idx].type + this.tasks[idx].col_id
                  break
                default:
                  this.tasks[idx].detail = null
                  this.tasks[idx].progress = 100
                  this.tasks[idx].error = ''
              }

              if (oldStatus !== this.tasks[idx].status) {
                this.refreshParent(idx, reloadChildren, newIndex)
              }
            }
          }
          this.updateActive()
        }).catch(error => {
          console.log(error)
        })
      },
      isInProgress (idx) {
        let task = this.tasks[idx]
        return (task.status === 'PROGRESS' || task.status === 'PENDING' || task.status === 'STARTED')
      },
      toDo (idx) {
        let task = this.tasks[idx]
        return (task.status === 'TODO' || task.status === 'FAILURE' || task.status === 'CANCELLED')
      },
      getType (idx) {
        let task = this.tasks[idx]
        return task.type.substring(0, 1).toUpperCase()
      },
      needProgressBar (idx) {
        let task = this.tasks[idx]
        return (task.type === 'I' && task.status === 'PROGRESS')
      },
      refreshParent (idx, reloadChildren, newIndex) {
        let task = this.tasks[idx]
        EventBus.$emit('refresh_node_button', task.col_id, task.type, task.status, reloadChildren, newIndex)
      }
    },
    created () {
      EventBus.$on('task_started', (type, model, add) => {
        let task = null
        let status = 'PROGRESS'
        if (add === true) {
          status = 'TODO'
        }
        switch (type) {
          case 'parse':
            task = model.parse_task
            type = 'P'
            break
          case 'index':
            task = model.index_task
            type = 'I'
            break
          case 'off':
            task = null
            type = 'O'
            break
        }

        let idx = this.exists(type, model.id)
        if (idx === null) {
          idx = this.tasks.push({
            type: type,
            col_title: model.title,
            col_id: model.id,
            task_id: task,
            status: status,
            detail: '',
            error: '',
            progress: 0,
            started: moment().format('YYYY-MM-DD h:mm:ss'),
            key: type + model.id
          })
          idx = idx - 1
        }

        if (!add) {
          this.controlTask(idx, true)
        }

        this.updateActive()
      })
    },
    mounted () {
      this.fetchTasks()
    }
  }
</script>

<style scoped>
  .progress {
    margin-top: 5px;
    min-width: 10px;
  }
  a {
      cursor: pointer;
  }
</style>
